import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { SupportArticle } from '@truphone/support-components'
import ContactSupportIcon from '../../images/icons/contact.svg'
import fetch from 'node-fetch'
import withQuery from 'with-query'
import { Helmet } from 'react-helmet'

function SupportArticleTemplate({
  data: { datoCmsSupportIndex, datoCmsSupportArticle, allDatoCmsSupportArticle, datoCmsSite },
  pageContext: { menu, footer, cookiePrompt, consumer, articleId, noIndex }
}) {
  const [views, setViews] = useState(null)
  const [showFeedback, setShowFeedback] = useState(false)

  useEffect(() => {
    const populateViewsCountAsync = async () => {
      const response = await fetch(
        withQuery(`${process.env.API_ENDPOINT}support/views/`, {
          slug: datoCmsSupportArticle.slug
        })
      )
      if (response.ok) {
        const json = await response.json()
        setViews(new Intl.NumberFormat().format(json.count + 1))
      }
    }

    const logViewAsync = async () => {
      await fetch(
        withQuery(`${process.env.API_ENDPOINT}support/views/`, {
          slug: datoCmsSupportArticle.slug
        }),
        { method: 'PUT' }
      )
    }

    populateViewsCountAsync()
    logViewAsync()

    setShowFeedback(datoCmsSupportIndex.feedbackEnabled && !hasGivenFeedback())
  }, [datoCmsSupportIndex.feedbackEnabled, datoCmsSupportArticle.slug])

  const hasGivenFeedback = () => {
    const feedback = window.localStorage.getItem('feedback')
    if (feedback) {
      const feedbackArray = JSON.parse(feedback)
      return feedbackArray.find((f) => f === datoCmsSupportArticle.slug)
    }
    return false
  }

  const recordFeedbackGiven = () => {
    const feedback = window.localStorage.getItem('feedback')
    let feedbackArray = []
    if (feedback) {
      feedbackArray = JSON.parse(feedback)
    }

    feedbackArray.push(datoCmsSupportArticle.slug)
    window.localStorage.setItem('feedback', JSON.stringify(feedbackArray))
  }

  const articleProducts = datoCmsSupportArticle.supportProducts.filter((supportProduct) =>
    datoCmsSupportIndex.supportProducts.find((indexProduct) => indexProduct.id == supportProduct.id)
  )

  const relatedArticles = allDatoCmsSupportArticle.edges
    .filter((edge) =>
      articleProducts.some(
        (articleProduct) =>
          !!edge.node.supportProducts.find(
            (relatedSupportProduct) => articleProduct.id === relatedSupportProduct.id
          )
      )
    )
    .map((edge) => ({
      text: edge.node.title,
      href: `${datoCmsSupportIndex.url}${edge.node.slug}/`,
      selected: edge.node.id === articleId,
      position: edge.node.position
    }))
    .sort((a, b) => a.position - b.position)

  return (
    <>
      <HelmetDatoCms
        seo={datoCmsSupportArticle.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <Helmet>
        {noIndex && <meta name="robots" content="noindex,nofollow" />}
        <meta
          className="swiftype"
          name="searchKey"
          data-type="string"
          content={datoCmsSupportIndex.searchKey}
        />
        <meta
          className="swiftype"
          name="published_at"
          data-type="date"
          content={datoCmsSupportArticle.meta.firstPublishedAt}
        />
        <meta className="swiftype" name="page_type" data-type="string" content="SupportArticle" />
      </Helmet>
      <TailwindLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt} consumer={consumer}>
        <SupportArticle
          dark={!consumer}
          title={datoCmsSupportArticle.title}
          subtitle={datoCmsSupportArticle.subtitle}
          content={datoCmsSupportArticle.content}
          articleType={datoCmsSupportArticle.articleType.title}
          views={views}
          viewsText={datoCmsSupportIndex.viewsText}
          relatesToText={datoCmsSupportIndex.relatesToText}
          relatedProducts={datoCmsSupportArticle.supportProducts
            .filter((product) =>
              datoCmsSupportIndex.supportProducts.some(
                (indexProduct) => product.id === indexProduct.id && !!product.url
              )
            )
            .map((product) => ({
              icon: product.icon?.url,
              title: product.title,
              href: `${datoCmsSupportIndex.url}${product.url ? product.url + '/' : ''}`
            }))}
          product={datoCmsSupportArticle.category.title}
          questions={relatedArticles}
          publishDate={new Date(datoCmsSupportArticle.meta.firstPublishedAt).toLocaleDateString(
            undefined, //Take the browser default language.
            {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            }
          )}
          banner={{
            icon: ContactSupportIcon,
            title: datoCmsSupportIndex.contactSupportTitle,
            ctas: datoCmsSupportIndex.contactSupportCtas
          }}
          feedback={{
            enabled: showFeedback,
            question: datoCmsSupportIndex.feedbackTitle,
            yes: datoCmsSupportIndex.feedbackYes,
            no: datoCmsSupportIndex.feedbackNo,
            thanksText: datoCmsSupportIndex.feedbackHelpfulMessage,
            sorryText: datoCmsSupportIndex.feedbackNotHelpfulMessage,
            improveText: datoCmsSupportIndex.feedbackNotHelpfulTextboxPlaceholder,
            submit: datoCmsSupportIndex.feedbackNotHelpfulCtaText,
            charactersRemaining: datoCmsSupportIndex.feedbackCharactersRemaining,
            charactersRemainingSingular: datoCmsSupportIndex.feedbackCharactersRemainingSingular,
            submitAction: (helpful, comment) => {
              recordFeedbackGiven()

              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'faq-feedback',
                event_category: 'Faq',
                event_action: helpful ? 'Helpful' : 'Not Helpful',
                event_label: helpful ? null : comment
              })
            }
          }}
        />
      </TailwindLayout>
    </>
  )
}

export default SupportArticleTemplate

export const query = graphql`
  query ($locale: String!, $indexId: String!, $articleId: String!, $articleCategoryId: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsSupportIndex(locale: { eq: $locale }, originalId: { eq: $indexId }) {
      searchKey
      url
      searchKey
      relatesToText
      viewsText
      supportProducts {
        id: originalId
      }
      contactSupportTitle
      contactSupportCtas {
        title
        text
        href
      }
      feedbackEnabled
      feedbackTitle
      feedbackYes
      feedbackNo
      feedbackHelpfulMessage
      feedbackNotHelpfulMessage
      feedbackNotHelpfulTextboxPlaceholder
      feedbackNotHelpfulCtaText
      feedbackCharactersRemaining
      feedbackCharactersRemainingSingular
    }
    datoCmsSupportArticle(locale: { eq: $locale }, originalId: { eq: $articleId }) {
      seoMetaTags {
        tags
      }
      title
      subtitle
      content
      articleType {
        title
      }
      category {
        title
      }
      supportProducts {
        id: originalId
        title
        icon {
          url
        }
        url: slug
      }
      meta {
        firstPublishedAt
      }
      slug
    }
    allDatoCmsSupportIndex(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id: originalId
          url
          supportProducts {
            id: originalId
          }
        }
      }
    }
    allDatoCmsSupportArticle(
      filter: {
        slug: { ne: null }
        locale: { eq: $locale }
        category: { originalId: { eq: $articleCategoryId } }
      }
    ) {
      edges {
        node {
          id: originalId
          title
          slug
          supportProducts {
            id: originalId
          }
          position
        }
      }
    }
  }
`
